






import { defineComponent } from "@vue/composition-api";
import Voucher from "@/views/Voucher.vue";

export default defineComponent({
    name: "RedeemVoucher",
    components: { Voucher }
})
